<template>
    <div class="AwardCardDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" style="text-align: right" size="small" label-width="85px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="会员卡类型">
                            <el-input placeholder="" v-model="form.cardTypeName" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="发卡状态">
                            <el-select v-model="card.statusList">
                                <el-option label="请选择" value="" />
                                <el-option label="待发卡" value="0" />
                                <el-option label="已发卡" value="1,2,3" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleQuery" size="small">查询</el-button>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <span>总记录&nbsp;&nbsp;</span>
            <span
                ><b>{{ page.total }}</b
                >条</span
            >
            <el-table border style="width: 100%" :data="tableData">
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="会员卡号" width="240" prop="cardPrintNo" />
                <el-table-column label="发卡状态" width="160" prop="status">
                    <template slot-scope="scope"
                        ><span>{{ scope.row.status | awardCardStatus }}</span></template
                    >
                </el-table-column>
                <el-table-column label="卡状态" width="160" prop="status">
                    <template slot-scope="scope"
                        ><span>{{ scope.row.status | status }}</span></template
                    >
                </el-table-column>
                <el-table-column label="发卡机构" width="120" prop="deptName" />
            </el-table>
            <ef-pagination :total="page.total" @pageChange="pageChange" />
        </el-card>
    </div>
</template>
<script>
import Utils from 'js/Util';
import EfPagination from 'components/EfPagination';

export default {
    name: 'AwardCardDetail',
    components: { EfPagination },
    props: ['form'],
    data() {
        return {
            tableData: [],
            card: {
                statusList: '',
                cardType: this.form.cardType,
                bizCode: this.form.code,
                deptGroupCode: this.form.deptGroupCode,
                page: 1,
                limit: Utils.Limit,
            },
            page: {
                total: 0,
                pageSizes: Utils.PageSizes,
                PageStyle: Utils.PageStyle,
            },
            url: {
                page: '/member/card/pageExtend',
            },
        };
    },
    filters: {
        status(status) {
            if (typeof status == 'undefined') {
                return '';
            }
            if (status == '0') {
                return '待售';
            }
            if (status == '1') {
                return '正常';
            }
            if (status == '2') {
                return '丢失';
            }
            if (status == '3') {
                return '作废';
            }
        },
        awardCardStatus(status) {
            if (status === 0) {
                return '待发卡';
            } else if (status === 1 || status === 2 || status === 3) {
                return '已发卡';
            } else {
                return '未知';
            }
        },
    },
    mounted() {
        const _this = this;
        _this.handleQuery();
    },
    methods: {
        handleQuery() {
            const _this = this;
            const _params = { params: _this.card };
            Utils.queryTable(_this, _this.url.page, _params);
        },
        pageChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.handleQuery();
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
